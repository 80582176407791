import request from '@/utils/request'
import qs from 'qs'

// 讲师管理
//列表
export const teacherManageList = (params) => {
    return request.post('/sys/teacher/list', params)
}

//新增讲师
export const teacherSave = (params) => {
    return request.post('/sys/teacher/save', params)
}

//编辑讲师
export const teacherUpdate = (params) => {
    return request.post('/sys/teacher/update', params)
}
    
//编辑讲师  通过驳回
export const teacherUpdateState = (params) => {
    return request.post('/sys/teacher/updateState', params)
}
//删除讲师
export const teacherRemove = (params) => {
    return request.post('/sys/teacher/remove', params)
}

//讲师详情
export const teacherDetail = (params) => {
    return request.post('/sys/teacher/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//讲师重置密码
export const teacherResetpwd = (params) => {
    return request.post('/sys/teacher/resetPwd', params)
}

//上传资质
export const teacherUpdateAuthFilee = (params) => {
    return request.post('/sys/teacher/updateFiles', params)
}

//获取资质详情
export const teacherFindAuthFile = (params) => {
    return request.post('/sys/teacher/findAuthFile', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//面试评分新增
export const teacherFaceMarkSave = (params) => {
    return request.post('/sys/teacherFaceMark/save', params)
}

//面试评分修改
export const teacherFaceMarkUpdate = (params) => {
    return request.post('/sys/teacherFaceMark/update', params)
}

//面试评分详情
export const teacherFaceMarkDetail = (params) => {
    return request.post('/sys/teacherFaceMark/getByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}



//面试答题列表
export const teacherQuestionList = (params) => { 
    return request.post('/sys/teacherQuestion/list', params)
}
//面试答题新增
export const teacherQuestionSave = (params) => {
    return request.post('/sys/teacherQuestion/save', params)
}

//面试答题删除
export const teacherQuestionRemove = (params) => {
    return request.post('/sys/teacherQuestion/remove', params)
}

//面试答题修改
export const teacherQuestionUpdate = (params) => {
    return request.post('/sys/teacherQuestion/update', params)
}

//面试答题详情
export const teacherQuestionInfo = (params) => {
    return request.post('/sys/teacherQuestion/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//面试答题保存
export const saveXmtTeacherInterviewAnswer = (params) => {
    return request.post('/sys/teacherAnswer/saveXmtTeacherInterviewAnswer', params)
}


//面试答题回显
export const teacherQuestionAnswer= (params) => {
    return request.post('/sys/teacherAnswer/getByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//面试答题答案根据老师id删除
export const removeByTeacher= (params) => {
    return request.post('/sys/teacherAnswer/removeByTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//试题列表
export const questionsList= (params) => {
    return request.post('/sys/a/exam/questions/list', params)
}


//试题列表新增
export const questionsSave= (params) => {
    return request.post('/sys/a/exam/questions/save', params)
}

//试题列表修改
export const questionsUpdate= (params) => {
    return request.post('/sys/a/exam/questions/update', params)
}


//试题列表删除
export const questionsRemove= (params) => {
    return request.post('/sys/a/exam/questions/remove', params)
}

//试题列表详情
export const questionsInfo= (params) => {
    return request.post('/sys/a/exam/questions/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点目录
export const catalog= (params) => {
    return request.post('/sys/a/exam/catalog/listSyncTree',  qs.stringify(params))
}

//知识点目录删除前判断
export const catalogCheckRemove= (params) => {
    return request.post('/sys/a/exam/catalog/checkRemove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点目录删除
export const catalogRemove= (params) => {
    return request.post('/sys/a/exam/catalog/remove', params)
}

//知识点目录新增
export const catalogSave= (params) => {
    return request.post('/sys/a/exam/catalog/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点目录编辑
export const catalogUpdate= (params) => {
    return request.post('/sys/a/exam/catalog/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//知识点目录详情
export const catalogInfo= (params) => {
    return request.post('/sys/a/exam/catalog/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}



//知识点列表
export const knowledge= (params) => {
    return request.post('/sys/a/exam/knowledge/listSyncTree', qs.stringify(params))
}

//知识点列表删除前判断
export const knowledgeCheckRemove= (params) => {
    return request.post('/sys/a/exam/knowledge/checkRemove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点列表删除
export const knowledgeRemove= (params) => {
    return request.post('/sys/a/exam/knowledge/remove', params)
}

//知识点列表新增
export const knowledgeSave= (params) => {
    return request.post('/sys/a/exam/knowledge/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点列表编辑
export const knowledgeUpdate= (params) => {
    return request.post('/sys/a/exam/knowledge/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//知识点详情
export const knowledgeInfo= (params) => {
    return request.post('/sys/a/exam/knowledge/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}







//教学目录
export const catalogList= (params) => {
    return request.post('/sys/a/system/catalog/listSyncTree', qs.stringify(params))
}
//教学目录新增
export const catalogSaveSystem= (params) => {
    return request.post('/sys/a/system/catalog/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//教学目录删除前判断
export const catalogCheckRemoveSystem= (params) => {
    return request.post('/sys/a/system/catalog/checkRemove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教学目录删除
export const catalogRemoveSystem= (params) => {
    return request.post('/sys/a/system/catalog/remove', params)
}

//教学目录详情
export const catalogInfoSystem= (params) => {
    return request.post('/sys/a/system/catalog/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教学目录编辑
export const catalogUpdateSystem= (params) => {
    return request.post('/sys/a/system/catalog/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}





// 试卷、、、、、、、、、、、、、、、、
//试卷列表
export const paperList= (params) => {
    return request.post('/sys/a/exam/paper/list', params)
}

//试卷列表删除
export const paperRemove= (params) => {
    return request.post('/sys/a/exam/paper/remove', params)
}

//试卷列表新增
export const paperSave= (params) => {
    return request.post('/sys/a/exam/paper/save', params)
}

//试卷列表编辑
export const paperUpdate= (params) => {
    return request.post('/sys/a/exam/paper/update', params)
}

//试卷列表详情
export const paperInfo= (params) => {
    return request.post('/sys/a/exam/paper/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//试卷列表预览
export const paperfindPaperQuestions= (params) => {
    return request.post('/sys/a/exam/paper/findPaperQuestions', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//组卷保存
export const savePaperQuestions= (params) => {
    return request.post('/sys/a/exam/paper/savePaperQuestions', params)
}

//录入员开始考试
export const startExam= (params) => {
    return request.post('/sys/teacher/startExam', params)
}

//查询测试试卷的内容接口
export const getFinishExamDetail= (params) => {
    return request.post('/sys/teacher/getFinishExamDetail', params)
}

//答题接口
export const answerQuestion= (params) => {
    return request.post('/sys/teacher/answerQuestion', params)
}

//提交试卷接口
export const submitFinishPaper= (params) => {
    return request.post('/sys/teacher/submitFinishPaper',params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//功底考试详情
export const findPaperQuestionsByTeacherId= (params) => {
    return request.post('/sys/a/exam/finishPaper/findPaperQuestionsByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//获取功底考试详情前判断
export const getFinishStatusByTeacherId= (params) => {
    return request.post('/sys/a/exam/finishPaper/getFinishStatusByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//审核员阅卷前获取该老师的试卷
export const finishPaperList= (params) => {
    return request.post('/sys/a/exam/finishPaper/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//审核员阅卷
export const findPaperQuestions= (params) => {
    return request.post('/sys/a/exam/finishPaper/findPaperQuestions', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//阅卷保存
export const updatePaperQuestions= (params) => {
    return request.post('/sys/a/exam/finishPaper/updatePaperQuestions', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//获取知识点名称
export const findCommonMacros= (params) => {
    return request.post('/sys/macro/findCommonMacros', params)
}

//知识点导入
export const importFile = (params) => {
    return request.post("/sys/examKnowledeg/importFile", params)
}

//老师学生课表
export const getSyllabusPageDeptOne= (params) => {
    return request.post('/sys/xmtProductItem/getSyllabusPageDeptOne', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//学段年级学科联动
export const getTeacherInfoByPeriodId = (params) => {
    return request.post('/sys/teacher/getTeacherInfoByPeriodId', params)
}

//修改新手机号
export const updatePhoneByTea= (params) => {
    return request.post('/sys/teacher/updatePhoneByTea', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}