<template>
  <div class="uplaod_video">
    <div class="video_area">
      <video :src="videoSrc" controls v-show="videoSrc"></video>
      <div v-show="!videoSrc">暂无</div>
    </div>
    <el-button
      type="primary"
      class="mL20"
      size="small"
      @click.stop="dealUploadVideo"
      >上传</el-button
    >
    <!-- <div class="tip mL20">* 视频大小在150M之内(mp4格式)</div> -->
    <div v-show="progressNum" class="mL20">上传状态：{{ progressNum }}</div>
    <input
      accept="video/mp4, video/ogg, video/webm"
      type="file"
      style="display: none"
      @change="fileUploadChange($refs.fileUpload.files[0])"
      ref="fileUpload"
    />
  </div>
</template>

<script>
import axios from "axios";
import { fileUpload } from "@/api/public";
export default {
  name: "uploadVideo",
  props: ['default_src'],
  data() {
    return {
      videoSrc: "",
      progressNum: "",
    };
  },
  watch: {
    default_src() { 
      this.videoSrc = this.default_src;
    
    },
  },
  created() {},
  methods: {
    dealUploadVideo() {
      this.$refs.fileUpload.click();
      this.$refs.fileUpload.value = "";
    },
    fileUploadChange(file) { 
      // if(file.size / 1024 / 1024 > 150){
      //   this.msgWarn('视频大小不能超过150M，请您重新选择！')
      //   return
      // }
      if(!/(\.mp4|\.ogg\.webm)$/.test(file.name)){
        this.msgWarn('视频格式不和要求，支持mp4，ogg，webm视频格式！')
        return
      }
      let data = new FormData();
      data.append("file", file);
      axios({
        url: fileUpload, 
        method: "post",
        data,
        //原生获取上传进度的事件
        onUploadProgress: (progressEvent) => {
            this.progressNum =
            (((progressEvent.loaded / progressEvent.total) * 100) | 0) + "%";
            if(this.progressNum == '100%'){
              this.progressNum = '处理中'
            }
        },
      })
        .then((res) => {
          this.videoSrc = res.data[0].url;
          this.progressNum = '已完成'
          this.$emit('video_change', this.videoSrc)
          this.msgSuccess("视频上传成功！");
        })
        .catch((err) => {
          console.log(err);
          this.msgError("视频上传失败！");
        });
    },
  },
  mounted() {
    this.progressNum="";
    this.videoSrc="";

  },
};
</script>
<style scoped lang="scss">
.uplaod_video {
  display: flex;
  align-items: center;
  .video_area {
    width: 200px;
    height: 140px;
    video {
      width: 200px;
      height: 140px;
      object-fit: fill;
    }
    div {
      width: 200px;
      height: 140px;
      border: 1px solid #dcdfe6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
/* @import url(); 引入css类 */
</style>