import request from '@/utils/request'

/* 资讯板块 */
export const systemAppNewsList = (params) => {
    return request.post('/business/InformationManage/list', params)
} 
export const systemAppNewsSave = (params) => {
    return request.post('/business/InformationManage/save', params)
}
export const systemAppNewsInfo = (params) => {
    return request.post('/business/InformationManage/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppNewsUpdate = (params) => {
    return request.post('/business/InformationManage/update', params)
}
export const systemAppNewsRemove = (params) => {
    return request.post('/business/InformationManage/remove', params)
}
export const systemAppNewsUpTop = (params) => {
    return request.post('/business/InformationManage/batchTop', params)
}
export const systemAppNewsNoTop = (params) => {
    return request.post('/business/InformationManage/batchNoTop', params)
}
/* Tags标签管理 */
export const systemAppTagsList = (params) => {
    return request.post('/business/TagsManage/list', params)
}
export const systemAppTagsRemove = (params) => {
    return request.post("/business/TagsManage/remove", params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppTagsSave = (params) => {
    return request.post('/business/TagsManage/save', params)
}
export const systemAppTagsInfo = (params) => {
    return request.post("/business/TagsManage/info", params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppTagsUpdate = (params) => {
    return request.post('/business/TagsManage/update', params)
}
/* 资讯内链管理 */
export const systemAppLinkList = (params) => {
    return request.post('/business/informationInternal/list', params)
}
export const systemAppLinkRemove = (params) => {
    return request.post('/business/informationInternal/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppLinkInfo = (params) => {
    return request.post('/business/informationInternal/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppLinkSave = (params) => {
    return request.post('/business/informationInternal/save', params)
}
export const systemAppLinkUpdate = (params) => {
    return request.post('/business/informationInternal/update', params)
}
/* banner管理 */
export const sysytemAppBannerList = (params) => {
    return request.post('/app/banner/list', params)
}
export const systemAppBannerRemove = (params) => {
    return request.post('/app/banner/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppBannerSave = (params) => {
    return request.post('/app/banner/save', params)
}
export const systemAppBannerUpdate = (params) => {
    return request.post('/app/banner/update', params)
}
export const systemAppBannerInfo = (params) => {
    return request.post('/app/banner/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppProduct = (params) => {
    return request.post('/business/product/listNoPage', params)
}


/* 好文推荐 */
export const systemAppArticleList = (params) => {
    return request.post('/app/article/list', params)
}
export const systemAppArticleRemove = (params) => {
    return request.post('/app/article/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppArticleSave = (params) => {
    return request.post('/app/article/save', params)
}
export const systemAppArticleInfo = (params) => {
    return request.post('/app/article/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAppArticleUpdate = (params) => {
    return request.post('/app/article/update', params)
}


/* 教师提现 */
export const systemAppTeacherCash = (params) => {
    return request.post('/customer/teacher/teacherCashConfig/saveOrUpdate', params)
}
/* 好文推荐-发布通知 */
export const sendArticle = (params) => {
    return request.post('/app/article/sendArticle', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}