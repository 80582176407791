<template>
  <div class="icon-select-area">
    <ul class="icon-box">
        <li v-for="(item, index) in iconList" :key="index" @click="selectIcon(item.icon)">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="`#${item.icon}`" />
          </svg>
          <span>{{item.name}}</span>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'icon-select',
  data() {
    return {
      iconList: [
        {icon: "icon-trash", name: '删除'},
        {icon: "icon-shanchu", name: '删除'},
        {icon: "icon-bofang-copy", name: '播放'},
        {icon: "icon-mima2", name: '密码'},
        {icon: "icon-bofang", name: '暂停'},
        {icon: "icon-quanxian", name: '权限'},
        {icon: "icon-bofang1", name: '播放'},
        {icon: "icon-quanxian1", name: '权限'},
        {icon: "icon-quanxian2", name: '权限'},
        {icon: "icon-edit", name: '编辑'},
        {icon: "icon-bianji", name: '编辑'},
        {icon: "icon-xinzeng", name: '新增'},
        {icon: "icon-zengjia", name: '新增'},
        {icon: "icon-shuaxin", name: '刷新'},
        {icon: "icon-bianji1", name: '编辑'},
        {icon: "icon-yiwenwenti", name: '疑问'},
        {icon: "icon-yiwen", name: '疑问'},
        {icon: "icon-wentigaishu_m", name: '疑问'},
        {icon: "icon-wenti_yiwen", name: '疑问'},
        {icon: "icon-sousuo", name: '搜索'},
        {icon: "icon-sousuo1", name: '搜索'},
        {icon: "icon-xingtaiduICON_sousuo--", name: '搜索'},
        {icon: "icon-tuichudenglu", name: '退出登录'},
        {icon: "icon-tuichudenglu1", name: '退出登录'},
        {icon: "icon-tuichudenglu2", name: '退出登录'},
        {icon: 'icon-tuichudenglu2', name: '退出登录'},
        {icon: 'icon-gengduo', name: 'loading'},
        {icon: 'icon-xingzhuang', name: 'loading'},
        {icon: 'icon-dengdai', name: 'loading'},
        {icon: 'icon-LoadingIndicator', name: 'loading'},
        {icon: 'icon-loading', name: 'laoding'},
        {icon: 'icon-jigou1', name:'机构'},
        {icon: 'icon-caidan1', name: '菜单'},
        {icon: 'icon-zixun1', name: '资讯'},
        {icon: "icon-biaoqian2", name: '标签'},
        {icon: "icon-tixian3", name: '提现'},
        {icon: "icon-banner1", name: 'banner'},
        {icon: "icon-zixun2", name: '资讯'},
        {icon: "icon-neilian", name: '内链'},
        {icon: "icon-tixian4", name: '提现'},
        {icon: "icon-jigou2", name: '机构'},
        {icon: "icon-xitong", name: '系统'},
        {icon: "icon-mima", name: '密码'},
        {icon: "icon-xitong1", name: '系统'},
        {icon: "icon-icon_neilianfenxiang", name: '分享'},
        {icon: "icon-mima1", name: '密码'},
        {icon: "icon-zidian", name: '提现'},
        {icon: "icon-book", name: '字典'},
        {icon: "icon-biaoqian", name: '标签'},
        {icon: "icon-icon_password", name: '密码'},
        {icon: "icon-tixian", name: '提现'},
        {icon: "icon-administrative", name: '行政'},
        {icon: "icon-tuijian", name: '推荐'},
        {icon: "icon-kecheng", name: '课程'},
        {icon: "icon-huo", name: '推荐'},
        {icon: "icon-tixian1", name: '提现'},
        {icon: "icon-user", name: '用户名'},
        {icon: "icon-jiaose", name: '角色'},
        {icon: "icon-jigou", name: '机构'},
        {icon: "icon-ljkc", name: '课程'},
        {icon: "icon-anquan", name: '安全'},
        {icon: "icon-caidan", name: '菜单'},
        {icon: "icon-hangzheng", name: '行政'},
        {icon: "icon-tixian2", name: '提现'},
        {icon: "icon-biaoqian1", name: '标签'},
        {icon: "icon-kecheng1", name: '课程'},
        {icon: "icon-hangzhengquyushezhi", name: '行政'},
        {icon: "icon-xingmingyonghumingnicheng", name: '用户'},
        {icon: "icon-jian", name: '推荐'},
        {icon: "icon-banner", name: 'banner'},
        {icon: "icon-zixun", name: '资讯'},
      ],
    };
  },
  methods: {
    selectIcon(icon){
      this.$emit('iconChange', icon)
    },
  },
  created() {},
  mounted() {
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.icon-box{
  height: 500px;
  padding: 0 10px;
  overflow: hidden;
  overflow-y: auto;
  li{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: left;
    cursor: pointer;
    svg{
      font-size: 26px;
      padding: 4px 10px 10px;
    }
  }
  li:hover{
    background-color: #909399;
    color: #ffffff;
  }
}
</style>