import request from '@/utils/request.js'
import qs from 'qs'
export const systemMenuUser = (params) => {
    return request.get('/sys/menu/user', params)
}
/* 用户管理 */
export const systemUserList = (params) => {
    return request.post('/sys/user/list', params)
}
export const systemUserIn = (params) => {
    return request.get('/sys/user/info', params)
}
export const systemUserPerms = (params) => {
    return request.get('/sys/user/perms', params)
}
/* 没有响应 */
export const systemUserResetPw = (params) => {
    return request.post('/sys/user/reset', params)
}
export const systemUserEnable = (params) => {
    return request.post('/sys/user/enable', params)
}
export const systemUserDisable = (params) => {
    return request.post('/sys/user/disable', params)
}
export const systemUserRoleList = (params) => {
    return request.get('/sys/role/select', params)
}
export const systemUserInfo = (params) => {
    return request.post('/sys/user/infoUser', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemUserInfoUpdate = (params) => {
    return request.post('/sys/user/update', params)
}
export const systemUserSave = (params) => {
    return request.post('/sys/user/save', params)
}
export const systemOrgList = (params) => {
    return request.post('/sys/org/list', params)
}
export const systemUserDelete = (params) => {
    return request.post('/sys/user/remove', params)
}



/* 角色管理 */
export const systemRoleList = (params) => {
    return request.post('/sys/role/list', params)
}
export const systemRoleAdd = (params) => {
    return request.post('/sys/role/save', params)
}
export const systemRoleUpdate = (params) => {
    return request.post('/sys/role/update', params)
}
export const systemRoleAuthOpt = (params) => {
    return request.post('/sys/role/authorize/opt', params)
}
export const systemRoleRemove = (params) => {
    return request.post('/sys/role/remove', params)
}
export const systemRoleInfo = (params) => {
    return request.post('/sys/role/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


/* 系统菜单 */
export const systemMenuList = (params) => {
    return request.post('/sys/menu/list', params)
}
export const systemMenuSelect = (params) => {
    return request.post('/sys/menu/select', params)
}
export const systemMenuSave = (params) => {
    return request.post('/sys/menu/save', params)
}
export const systemMenuUpdate = (params) => {
    return request.post('/sys/menu/update', params)
}
export const systemMenuRemove = (params) => {
    return request.post('/sys/menu/remove', params)
}
export const systemMenuInfo = (params) => {
    return request.post('/sys/menu/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


/* 机构管理 */
export const systemOrgTreeList = (params) => {
    return request.post('/sys/org/treeList', params)
}
export const systemOrgSave = (params) => {
    return request.post('/sys/org/save', params)
}
export const systemOrgSelect = (params) => {
    return request.post('/sys/org/select', params)
}
export const systemOrgUpdate = (params) => {
    return request.post('/sys/org/update', params)
}
export const systemOrgRemove = (params) => {
    return request.post('/sys/org/remove', params)
}
export const systemOrgInfo = (params) => {
    return request.post('/sys/org/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 通用字典 */
export const systemMacroList = (params) => {
    return request.post('/sys/macro/list', params)
}
export const systemMacroSelect = (params) => {
    return request.post('/sys/macro/select', params)
}
export const systemMacroSave = (params) => {
    return request.post('/sys/macro/save', params)
}
export const systemMacroUpdate = (params) => {
    return request.post('/sys/macro/update', params)
}
export const systemMacroInfo = (params) => {
    return request.post('/sys/macro/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemMacroRemove = (params) => {
    return request.post('/sys/macro/remove', params)
}
/* 课程体系 */
export const systemCourseListSyncTree = (params) => {
    return request.post('/sys/courseEntry/listSyncTree', qs.stringify(params))
}
export const systemCourseSave = (params) => {
    return request.post('/sys/courseEntry/save', params)
}
export const systemCourseRemove = (params) => {
    return request.post('/sys/courseEntry/remove', params)
}
export const systemCourseUpdate = (params) => {
    return request.post('/sys/courseEntry/update', params)
}
export const systemCourseInfo = (params) => {
    return request.post('/sys/courseEntry/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 行政区域 */
export const systemAreaSelect = (params) => {
    return request.get('/sys/area/select', params)
}
export const systemAreaList = (params) => { 
    return request.post('/sys/area/list', params)
}
export const systemAreaInfo = (params) => {
    return request.post('/sys/area/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const systemAreaSave = (params) => {
    return request.post('/sys/area/save', params)
}
export const systemAreaUpdate = (params) => {
    return request.post('/sys/area/update', params)
}
export const systemAreaRemove = (params) => {
    return request.post('/sys/area/remove', params)
}

// 下载模板

// export const downloadExcel = (params) => {
//     return request.post('/sys/student/downloadExcel', params, {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     })
// }
export const downloadExcel = (params) => {
    return request.post('/sys/student/downloadExcel', params)
}
//老师学生课表通知
export const sendCourseRemind = (params) => {
    return request.post('/sys/xmtProductItem/sendCourseRemind', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课表查询通知
export const batchSendCourseRemind = (params) => {
    return request.post('/sys/xmtProductItem/batchSendCourseRemind', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课表查询一键通知
export const oneClickNotification = (params) => {
    return request.post('/sys/xmtProductItem/oneClickNotification', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//角色上传头像
export const updatePhoto = (params) => {
    return request.post('/sys/user/updatePhoto', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//学员管理-跟进记录-跟进程度等级设置
export const setStudentlevel = (params) => {
    return request.post('/sys/student/setStudentlevel', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


/* 机构余额清零 */
export const organRechargeInit = (params) => {
    return request.post(`/sys/organRecharge/balanceInit?${qs.stringify(params, { indices: false })}`)
}

/* 平台机构充值列表 */
export const organRechargeList = (params) => {
    return request.get(`/sys/organRecharge/page/list`, params)
}

/* 机构交易明细列表 */
export const organExchargeDetailList = (params) => {
    return request.get(`/sys/organRecharge/pageDetail/list`, params)
}

/* 流量费充值 */
export const organRechargeSave = (params) => {
    return request.post('/sys/organRecharge/save', params)
}

/* 平台机构集合 */
export const getOrganList = () => {
    return request.get('/sys/organRecharge/organList')
}

/* 机构交易明细列表（平台查看机构交易明细） */
export const transationList = (params) => {
    return request.get('/sys/transation/page/list', params)
}

/* 查询机构当前余额 */
export const getOrganBalance = () => {
    return request.get('/sys/organ/detail')
}

/* 流量费设置删除 */
export const flowFeeDel = (params) => {
    return request.post(`/sys/flowFee/deleteById?${qs.stringify(params, { indices: false })}`)
}

/* 上课模式和视频模式查询对应的流量费 */
export const getFlowFeeByMode = (params) => {
    return request.get('/sys/flowFee/getFlowFeeByMode', params)
}

/* 流量费详情 */
export const getFlowFeeById = (params) => {
    return request.get('/sys/flowFee/getFlowFeeByOrgId', params)
}

/* 平台流量费设置列表 */
export const flowFeeList = (params) => {
    return request.get('/sys/flowFee/page/list', params)
}

/* 流量费设置新增 */
export const flowFeeSave = (params) => {
    return request.post('/sys/flowFee/save', params)
}

/* 设置启用禁用 */
export const flowFeeEnable = (params) => {
    return request.post(`/sys/flowFee/setFlowFee?${qs.stringify(params, { indices: false })}`)
}

/* 流量费用更新 */
export const flowFeeUpdate = (params) => {
    return request.post(`/sys/flowFee/update`, params)
}