import request from '@/utils/request'
import qs from 'qs'
export const publicSourceList = (params) => {
    return request.post( '/sys/publicSource/list', params)
}
export const publicSourceSave = (params) => {
    return request.post('/sys/publicSource/save', params)
}
export const publicSourceInfo = (params) => {
    return request.post('/sys/publicSource/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const publicSourceUpdate = (params) => {
    return request.post('/sys/publicSource/update', params)
}
export const publicSourceRemove = (params) => {
    return request.post('/sys/publicSource/remove', params)
}

/* 知识点树状图 */
/* 参数 parentId */
export const knowledgeTree = (params) => {
    return request.post('/interface/exam/knowledge/listSyncTree', qs.stringify(params))
}