import request from '@/utils/request'

/* 客户列表接口 */
export const studentProcessList = (params) => {
    return request.post('/sys/student/myClient', params)
}
/* 被释放客户 */
export const studentReleaseList = (params) => {
    return request.post('/sys/student/listRelease', params)
}
/* 销售获取资源可领取数量接口 */
export const getAvliableNum = (params) => {
    return request.post('/sys/student/sale/getAvliableNum', params) 
}
/* 销售申请领取用户接口 */
export const saleApply = (params) => {
    return request.post('/sys/student/sale/apply', params)
}
/* 跟进列表接口不分页 */
export const followUpList = (params) => {
    return request.post('/sys/followup/listNoPage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 跟进列表接口分页 */
export const followUpListPage = (params) => {
    return request.post('/sys/followup/list', params)
}
/* 新增跟进记录 */
export const followupSave = (params) => {
    return request.post('/sys/followup/save', params)
}
/* 根据id查询跟进详情 */
export const followUpInfo = (params) => {
    return request.post('/sys/followup/info', params)
}
/* 修改跟进记录表 */
export const followUpUpdate = (params) => {
    return request.post('/sys/followup/update', params)
}
/* 真实删除 */
export const trueDel = (params) => {
    return request.post('/sys/student/trueDel', params)
}
/* 批量回归客户池 */
export const batchFlyBack = (params) => {
    return request.post('/sys/student/batchFlyBack', params)
}
/* 上一个下一个学生 */
export const prevAndNext = (params) => {
    return request.post('/sys/student/getBackAndNextStudentInfo', params)
}
/* 下单 */
export const addOrder = (params) => {
    return request.post('sys/order/addOrder', params)
}
/* 订单详情 */
export const orderDetail = (params) => {
    return request.post('/sys/order/findDetail', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 驳回申请查询接口 */
export const orderInfo = (params) => {
    return request.post('/sys/order/info', params)
}
/* 驳回申请确认接口 */
export const orderUpdate = (params) => {
    return request.post('/sys/order/update', params)
}
/* 订单列表 */
export const orderList = (params) => {
    return request.post('/sys/order/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const orderListNoPage = (params) => {
    return request.post('/sys/order/listNoPage', params)
}
export const productRevenue = (params) => {
    return request.post('/sys/order/productRevenue', params)
}
/* 删除订单 */
export const orderRemove = (params) => {
    return request.post('/sys/order/remove', params)
}
/* 检测学生之前是否下过该一对一订单 */
export const checkOneToOneOrder = (params) => {
    return request.post('/sys/order/orderStatusCheckOneToOne', params)
}

/* 上课记录 */
export const studentClassRecord = (params) => {
    return request.post('/sys/finance/studentClassRecord', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 财务产品收入统计接口 */
export const orderCountForList = (params) => {
    return request.post('/sys/order/orderCountForList', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 我的订单  删除 */
export const logicalDeletion = (params) => {
    return request.post('/sys/order/logicalDeletion', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 产品收入一对一充值列表*/
export const productRevenueForOto = (params) => {
    return request.post('/sys/order/productRevenueForOto', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}