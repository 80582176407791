import request from '@/utils/request'
import qs from 'qs'

/* 产品列表 一对一 一对多 公开课 */
export const productList = (params) => {
    return request.post( '/sys/product/list', params)
}
/* 产品删除 一对一 一对多 公开课 */
export const productRemove = (params) => {
    return request.post('/sys/product/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 产品更新 是否置顶  发布 上线 下架 （公开课更新）*/
export const productUpdateCourse = (params) => {
    return request.post('/sys/product/updateCourse', params)
}
/* 产品详情 公开课 */
export const productInfo = (params) => {
    return request.post('/sys/product/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 产品新增 公开课 */
export const productOnToAllAdd = (params) => {
    return request.post('/sys/product/saveCourse', params)
}
/* 产品新增 一对一 一对多 */
export const productAdd = (params) => {
    return request.post('/sys/product/save', params)
}
/* 产品更新 一对一 一对多 */
export const productUpdate = (params) => {
    return request.post('/sys/product/update', params)
}
/* 产品详情 一对一 一对多 */
export const productInfoDetail = (params) => {
    return request.post('/sys/product/info/detail', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 产品列表 */
export const productListNoPage = (params) => {
    return request.post('sys/product/listNoPage', params)
}
/* 公开课-小程序推送 */
export const setShowWechatStatus = (params) => {
    return request.post('/sys/product/setShowWechatStatus', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 公开课配置讲师列表-排课里的获取讲师也可以用 */
export const configteacherPlanList = (params) => {
    return request.post('/sys/configteacherPlan/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 公开课排课接口 */
export const saveLiveBroadcastClass = (params) => {
    return request.post('/sys/xmtProductItem/saveLiveBroadcastClass', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 公开课排课列表 */
export const findCourseItem = (params) => {
    return request.post('/sys/xmtProductItem/findCourseItem', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 公开课排课删除 */
export const deleteLiveBroadcastClass = (params) => {
    return request.post('/sys/xmtProductItem/deleteLiveBroadcastClass', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 购买人，会员人等学员列表 */
export const getWechatProductUserList = (params) => {
    return request.post('/sys/product/getWechatProductUserList', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 停留详情列表 */
export const getWechatProductUserTimeList = (params) => {
    return request.post('/sys/product/getWechatProductUserTimeList', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}