import request from '@/utils/request'

/* 创建群接口 */
/* 参数 groupId,groupName,teachManagerId,teacherId,studentId,subjectId,configteacherPlanId */
export const saveGroup = (params) => {
    return request.post('/sys/mainGroup/save', params)
}
/* 更新群成员接口 */
export const updateGroup = (params) => {
    return request.post('/sys/mainGroup/update', params)
}
/* 后台创建群聊 */
export const saveGroupServe = (params) => {
    return request.post('/sys/configteacherPlan/createGroup', params)
}