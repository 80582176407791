import request from '@/utils/request'
import qs from 'qs'


//部门学员///////////////////////////////////////////////////////
//部门学员未分配
export const unDistribute = (params) => {
    return request.post('/sys/student/unDistribute', params)
}
//部门学员已分配
export const alreadyDistribute = (params) => {
    return request.post('/sys/student/alreadyDistribute', params)
}


//查看该部门学管底下的学管专员
export const listNoPage = (params) => {
    return request.post('/sys/user/listNoPage', params)
}

//财务课时统计
export const financialClassHours = (params) => {
    return request.post('/sys/user/financialClassHours', params)
}
//教务主管分配学生给教务专员
export const updateTeachManagerId = (params) => {
    return request.post('/sys/order/updateTeachManagerIdYDY', params)
}


//学生配置课程列表接口
export const configureCourseList = (params) => {
    return request.post('/sys/configureCourse/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生添加配置课程接口
export const configureCourseSave = (params) => {
    return request.post('/sys/configureCourse/batchSave', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生配置课程删除接口
export const configureCourseRemove = (params) => {
    return request.post('/sys/configureCourse/remove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//学生配置课程保存钱删除
export const removeByStudentId = (params) => {
    return request.post('/sys/configureCourse/removeByStudentId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//一对多分配列表接口
export const distributeMore = (params) => {
    return request.post('/sys/order/distributeMore', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//一对多教务主管分配学生给教务专员
export const updateTeachManagerIdYDD = (params) => {
    return request.post('/sys/order/updateTeachManagerIdYDD', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 订单详情 */
export const orderDetail = (params) => {
    return request.post('/sys/order/findDetail', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多学管专员退回订单接口 */
export const goBackOrder = (params) => {
    return request.post('/sys/order/goBackOrder', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


/* 一对多学生列表学生加入班级，展示班级名称列表接口 */
export const getNameByProductId = (params) => {
    return request.post('/sys/classroom/getNameByProductId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 选中班级后，确认加入班级 */
export const classroomStudentSave = (params) => {
    return request.post('/sys/classroomStudent/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多班级列表接口 */
export const classroomList = (params) => {
    return request.post('/sys/classroom/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多班级新增接口 */
export const classroomSave = (params) => {
    return request.post('/sys/classroom/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多班级详情接口 */
export const classroomInfo = (params) => {
    return request.post('/sys/classroom/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多班级修改接口 */
export const classroomUpdate = (params) => {
    return request.post('/sys/classroom/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多班级删除接口 */
export const classroomRemove = (params) => {
    return request.post('/sys/classroom/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 班级内学生列表接口 */
export const classroomStudentList = (params) => {
    return request.post('/sys/classroomStudent/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 班级内删除学生接口 */
export const classroomStudentRemove = (params) => {
    return request.post('/sys/classroomStudent/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 对多班级添加学员列表接口 */
export const getStudentByProductId = (params) => {
    return request.post('/sys/product/getStudentByProductId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 班级内确定添加学生接口 */
export const classroomStudentBatchSave = (params) => {
    return request.post('/sys/classroomStudent/batchSave', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


/* 一对多单次排课接口 */
export const saveOneToMore = (params) => {
    return request.post('/sys/xmtProductItem/saveOneToMore', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 一对多批量排课接口 */
export const batchSaveOneToMore = (params) => {
    return request.post('/sys/xmtProductItem/batchSaveOneToMore', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 退费审核列表 */
export const studentRefundList = (params) => {
    return request.post('/sys/studentRefund/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 退费审核详情 */
export const studentRefundInfo = (params) => {
    return request.post('/sys/studentRefund/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 退费审核同意或者驳回 */
export const studentRefundUpdate = (params) => {
    return request.post('/sys/studentRefund/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 退费审核教务提交接口 */
export const studentRefundSave = (params) => {
    return request.post('/sys/studentRefund/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 学生状态接口 */
export const selectStudentState = (params) => {
    return request.post('/sys/student/selectStudentState', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}



/* 学生详情点学生退费调的详情接口 */
export const refundOrderInfos = (params) => {
    return request.post('/sys/studentRefund/refundOrderInfos', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 我的学员删除 */
export const studentDelete = (params) => {
    return request.post('/sys/student/remove', params)
}

/* 学员管理里的一对多 */
export const alreadyDistributeMore = (params) => {
    return request.post('/sys/student/alreadyDistributeMore', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 学习报告列表 */
export const studentStudyReportList = (params) => {
    return request.post('/sys/studentStudyReport/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 学习报告保存 */
export const studentStudyReportSave = (params) => {
    return request.post('/sys/studentStudyReport/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 学习报告删除 */
export const studentStudyReportRemove = (params) => {
    return request.post('/sys/studentStudyReport/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 学习报告 */
export const studentStudyReportUpdate = (params) => {
    return request.post('/sys/studentStudyReport/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 学习报告发布
export const sendStudyReport = (params) => {
    return request.post('/sys/studentStudyReport/sendStudyReport', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 学习报告详情 */
export const studentStudyReportInfo = (params) => {
    return request.post('/sys/studentStudyReport/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 学情报告获取统计 */
export const getChartData = (params) => {
    return request.post('/sys/studentStudyReport/getChartData', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 一对一监课列表
export const getSupervisionCoursePage = (params) => {
    return request.post('/sys/supervisionCourse/getSupervisionCoursePage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 一对一监课统计相关
export const getSupervisionCourseCount = (params) => {
    return request.post('/sys/supervisionCourse/getSupervisionCourseCount', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 一对多监课列表
export const getOneToMoreSupervisionCoursePage = (params) => {
    return request.post('/sys/supervisionCourse/getOneToMoreSupervisionCoursePage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 一对多监课统计
export const getOneToMoreSupervisionCourseCount = (params) => {
    return request.post('/sys/supervisionCourse/getOneToMoreSupervisionCourseCount', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 回放管理列表-一对一
export const getPlaybackCoursePage = (params) => {
    return request.post('/sys/supervisionCourse/getPlaybackCoursePage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 回放管理列表-一对多
export const getOneToMorePlaybackCoursePage = (params) => {
    return request.post('/sys/supervisionCourse/getOneToMorePlaybackCoursePage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 回放管理在线打开-扫码观看
export const getRecordingUrlByItemId = (params) => {
    return request.post('/sys/supervisionCourse/getRecordingUrlByItemId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 退费前判断
export const checkRefundOneToOne = (params) => {
    return request.post('/sys/studentRefund/checkRefundOneToOne', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 学员管理-我的学员列表-上面三个的统计数值
export const alreadyDistributeCount = (params) => {
    return request.post('/sys/student/alreadyDistributeCount', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

// 学员管理-批量排课获取产品
export const getProductByStudentIdForBatch = (params) => {
    return request.post('sys/product/getProductByStudentIdForBatch', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 学生详情-订单列表-余额转充值
export const balanceRecharge = (params) => {
    return request.post('/sys/order/balanceRecharge', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 存储回放二维码
export const saveCallBackQrCode = (params) => {
    return request.post('/sys/supervisionCourse/saveCallBackQrCode', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 获取域名
export const getAddressIp = (params) => {
    return request.post('/interface/xmtCommon/getAddressIp', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 学生的启用禁用
export const setStudentStatus = (params) => {
    return request.post('/sys/student/setStudentStatus', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 查看学生剩余课时
export const getRemainingClassHours = (params) => {
    return request.post('/sys/student/getRemainingClassHours', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 剩余课时中查看单价表
export const studentUnitPriceChildInfo = (params) => {
    return request.post('/sys/studentUnitPriceChild/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}