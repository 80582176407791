import request from '@/utils/request'

/* 财务订单审核列表 */
export const financeOrderList = (params) => {
    return request.post('/sys/finance/order/list', params)
}
/* 通过申请接口 */
export const orderAgree = (params) => {
    return request.post('/sys/finance/order/agree', params)
}

/* 退款明细 */
export const studentRefundList = (params) => {
    return request.post('/sys/studentRefund/list', params)
}

/* 退款审核接口 */
export const studentRefundUpdate = (params) => {
    return request.post('/sys/studentRefund/update', params)
}

/* 财务 提现待审核列表 */
export const teacherPayWagesList = (params) => {
    return request.post('/sys/teacherPayWages/list', params)
}

/* 财务 提现待审核  工资汇总 */
export const summaryTeacherSalary = (params) => {
    return request.post('/sys/teacherPayWages/summaryTeacherSalary', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 财务 提现待审核列表  教师课酬详情 */
export const getInfoByTeacherId = (params) => {
    return request.post('/sys/teacherPayWages/getInfoByTeacherId', params)
}

/* 财务 提现待审核列表  打款 */
export const teacherPayWagesUpdate= (params) => {
    return request.post('sys/teacherPayWages/update', params)
}
/* 财务 提现待审核 发送给老师 */
export const teacherPayWagesSendMsg= (params) => {
    return request.post('sys/teacherPayWages/teacherPayWagesSendMsg', params)
}
/* 财务 导出 */
export const importByTime= (params) => {
    return request.post('sys/teacherPayWages/importByTime', params)
}

/* 查看异常 */
export const teacherPayWagesAbnormal= (params) => {
    return request.post('sys/teacherPayWagesAbnormal/list', params)
}

/* 课时统计 */
export const statisticsOfFinancialClassHours= (params) => {
    return request.post('sys/xmtProductItem/statisticsOfFinancialClassHours', params)
}

/* 课酬设置列表 */
export const getfixedClassPayList= (params) => {
    return request.post('sys/teacher/getfixedClassPayList', params)
}

/* 修改固定课酬 */
export const updatefixedClassPayList= (params) => {
    return request.post('sys/teacher/updatefixedClassPayList', params)
}
/* 设置课酬 详情页 */
export const getTeacherClassPayList= (params) => {
    return request.post('sys/configteacherPlan/getTeacherClassPayList', params)
}
/* -详情页-设置课酬 */
export const setConfigteacherClassPay= (params) => {
    return request.post('/sys/configteacherClassPay/setConfigteacherClassPay', params)
}
/* -详情页-历史课酬列表 */
export const configteacherClassPayList= (params) => {
    return request.post('/sys/configteacherClassPay/list', params)
}
/* -财务- 一键打款 */
export const batchApply= (params) => {
    return request.post('/sys/teacherPayWages/batchApply', params)
}
/* -财务- 学生详情 */
export const getProductItemInfoByTeacherId= (params) => {
    return request.post('/sys/teacherPayWages/getProductItemInfoByTeacherId', params)
}
/* -财务- 单价设置列表接口 */
export const studentUnitPriceList= (params) => {
    return request.post('/sys/studentUnitPrice/list', params)
}
/* -财务- 价格表-列表 */
export const studentUnitPriceChildList= (params) => {
    return request.post('/sys/studentUnitPriceChild/list', params)
}
/* -财务- 价格表-新增 */
export const studentUnitPriceChildaSave= (params) => {
    return request.post('/sys/studentUnitPriceChild/save', params)
}
/* -财务- 价格表-修改 */
export const studentUnitPriceChildaUpdate= (params) => {
    return request.post('/sys/studentUnitPriceChild/update', params)
}
/* -财务- 价格表-删除 */
export const studentUnitPriceChildaRemove= (params) => {
    return request.post('/sys/studentUnitPriceChild/remove', params)
}
/* -财务- 价格表-自定义列表里的启用禁用 */
export const setCustomPriceStatu= (params) => {
    return request.post('/sys/studentUnitPriceChild/setCustomPriceStatu', params)
}
/* -财务- 价格表-查看详情接口 */
export const studentUnitPriceChildInfo = (params) => {
    return request.post('/sys/studentUnitPriceChild/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* -设置固定课酬 */
export const setFixPrice= (params) => {
    return request.post('/sys/studentUnitPriceChild/setFixPrice', params)
}
/* -设置模式 */
export const setType= (params) => {
    return request.post('/sys/studentUnitPrice/setType', params)
}

/* -修改模式前查询当前所属信息 */
export const selectModelInfoByStudentId= (params) => {
    return request.post('/sys/studentUnitPrice/selectModelInfoByStudentId', params)
}
// 产品收入-获取小组
export const getOrgList = (params) => {
    return request.post('/sys/org/getOrgList', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 产品收入-获取小组成员
export const getUserListByOrgIds = (params) => {
    return request.post('/sys/user/getUserListByOrgIds', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 隐藏显示
export const configteacherPlanUpdate = (params) => {
    return request.post('/sys/configteacherPlan/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}