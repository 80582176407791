import request from '@/utils/request'
import qs from 'qs'

export const getPurposeTeacherList = (params) => {
    return request.post('', params)
}
/* 新增或修改新课需求 */
export const saveOrUpdateNewCourseNeeds = (params) => {
    return request.post('/sys/xmtTeachManagerChat/saveOrUpdateNewCourseNeeds', params)
}
/*  */
export const getNewCourseNeedsBySubjectId = (params) => {
    return request.post('', params)
}

export const getSubjectList = (params) => {
    return request.post('', params)
}

export const getMyStudent = (params) => {
    return request.post('', params)
}

export const updateConcernTeachManager = (params) => {
    return request.post('', params)
}

export const getChatUserDetail = (params) => {
    return request.post('', params)
}

export const getStudentDetail = (params) => {
    return request.post('', params)
}

/* 教务端查询学生列表 */
/* 参数：pageNo     pageSize     teacherId */
export const getStudentPage = (params) => {
    return request.post('/sys/xmtTeachManagerChat/getStudentPage', params)
}

export const getDeleteQuestionById = (params) => {
    return request.post('', params)
}

export const saveOrUpdateQuestion = (params) => {
    return request.post('', params)
}


/* 后台聊天所需接口 */

/* 教务搜索好友 */
/* "name":"其他学管专员" */
export const searchFriend = (params) => {
    return request.post('/sys/xmtTeachManagerChat/searchFriend', params)
}

/* 我的同事 */
/* 无参数 */
export const myColleagues = (params) => {
    return request.post('/sys/xmtTeachManagerChat/myColleagues', params)
}

/* 我的学生/家长 */
/* 无参数 */
export const myStuAndPar = (params) => {
    return request.post('/sys/xmtTeachManagerChat/myStuAndPar', params)
}

/* 我的老师列表接口 */
/*  如果点相互关注，参数传：concernType  3；  如果点关注我的，参数传：concernType  2 ； 如果点我的关注，参数传：concernType  1 */
export const concernRelationship = (params) => {
    return request.post('/sys/xmtTeachManagerChat/concernRelationship', params)
}

/* 新增老师关注讲师 */
/* "teacherId":"1234",
    "teachManagerId":"4321",
    "concernType":"1" （传1）：关注类型 0:取关1:教务关注讲师 2:讲师关注教务 3:互关 */
export const saveConcernTeacher = (params) => {
    return request.post('/sys/xmtTeachManagerChat/saveConcernTeacher', params)
}

/* 修改教务关注讲师（回关、取关） */
/* id, concernType ;1：如果列表concernType返回值为3，则教务取消关注老师 ----"concernType":2
	    如果列表concernType返回值为3，则老师取消关注教务----"concernType":1
    2：如果列表concernType返回值为1或者2，则教务取消关注老师或者讲师取关教务---"concernType":0 */
export const updateConcernTeacher = (params) => {
    return request.post('/sys/xmtTeachManagerChat/updateConcernTeacher', params)
}

/* 根据学生id和学科id查看学生新课需求 */
/* "studentId":"88",
    "subjectId":"23" */
export const getNewCourseNeedsBySubjectIdAndStudentId = (params) => {
    return request.post('/sys/xmtTeachManagerChat/getNewCourseNeedsBySubjectIdAndStudentId', params)
}

/* 上传作业详情 */
/* "itemId":"2180",
    "homeWorkId":"1275" */
export const findItemHomeWork = (params) => {
    return request.post('/sys/xmtTeachManagerChat/findItemHomeWork', params)
}

/* 学生作业列表 */
/* "pageSize":10,
    "pageNo":1,
    "studentId":"1380",
    "teacherId":"",
    "subjectId":"",
    "fileStatus":"",   //0 未批阅 1已批阅 2已回传
    "searchBeginDate":null,
    "searchEndDate":null */
export const studentItemWorkPage = (params) => {
    return request.post('/sys/xmtTeachManagerChat/studentItemWorkPage', params)
}

/* 学生问题列表 */
/* "teacherId":"350",
    "createUserId":"1380",
    "pageNo":"1",
    "pageSize":"10" */
export const studentQuestionPage = (params) => {
    return request.post('/sys/xmtTeachManagerChat/studentQuestionPage', params)
}

/* 学生问题整理详情 */
/* "id":"10" */
export const getQuestionById = (params) => {
    return request.post('/sys/xmtTeachManagerChat/getQuestionById', params)
}

/* 新课需求查询系统中所有科目 */
/* 无参数 */
export const getAllSubject = (params) => {
    return request.post('/sys/xmtTeachManagerChat/getAllSubject', params)
}

/* 根据id查看学生新课需求 */
/* "id":"1" */
export const getNewCourseNeedsById = (params) => {
    return request.post("/sys/xmtTeachManagerChat/getNewCourseNeedsById", params)
}

/* 学生退费--提交审核 */
/* "backReason": "1",  退回理由
    "bankAccount": "1",  银行账号
    "bankAddress": "1",  开户行网点
    "bankUserName": "1",  开户人姓名
    "bankUserPhone": "1",   开户人手机(平台账号)
    "orderIds": "24",   订单id（多个逗号隔开）
    "realityRefundAmount": 50,  实际退款金额
    "realityUsePrice": 0,  实际消耗金额
    "refundAmount": 50,  实际金额
    "studentId": 11  学生id
     */
export const studentRefundSave = (params) =>  {
    return request.post('/sys/studentRefund/save', params)
}

/* 学生退费--退费时选中的某些订单的详情 */
/* [121, 125] */
export const refundOrderInfos = (params) => {
    return request.post('/sys/studentRefund/refundOrderInfos', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 学生退费--校验订单是否发起过退费 */
/* "orderIds": "121,125",
    "studentId": 85 */
/* false 表示不可以操作 */
export const checkRefundOrders = (params) => {
    return request.post('/sys/studentRefund/checkRefundOrders', params)
}

/* 学生退费--学生详情 */
/* id就传数字：
 如：85 */
 export const checkRefundInfo = (params) => {
     return request.post('/sys/student/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
 }

 /* 课堂反馈详情 */
 /* id就传数字：
 如：85 */
export const getLearnReportInfo = (params) => {
    return request.post('/sys/productItemLearnReport/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 更新课堂反馈 */
export const updateLearnReport = (params) => {
    return request.post('/sys/productItemLearnReport/saveOrUpdate', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 章节知识点相关 */
/* parentId: 320005 */
export const knowledgeTree = (params) => {
    return request.post('/sys/a/exam/knowledge/listSyncTree', qs.stringify(params))
}

/* 文件上传 */
export const fileUpload = (params) => {
    return request.post('/interface/file/upload', params)
}

/* 获取老师真实姓名 */
export const getTeacherRealName = (params) => {
    return request.post('/sys/teacher/getTeacherRealName', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 获取老师详情信息 */
export const getTeacherDetail = (params) => {
    return request.post('/sys/teacher/getThacherDetailForChat', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 是否主群 */ 
export const getIsMainGroup = (params) => {
    return request.post('/sys/teacher/getMainGroupFlag', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* 获取学生家长详情 */
export const getParentsDetail = (params) => {
    return request.post('/sys/student/getStudentDetailForChat', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const fileUploadUrl = '/interface/file/upload'