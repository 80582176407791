import request from '@/utils/request'
import qs from 'qs'

//教务管理
//教务订单///////////////////////////////////////////////////////
export const teacherManagerOrder = (params) => {
    return request.post('/sys/order/teacherManagerOrder', params)
}

//教师管理/////////////////////////////////////////////////////

export const listTeacherManager = (params) => {
    return request.post('/sys/teacher/listTeacherManager', params)
}
//获取见识过是是否关注
export const isConcernTeacher = (params) => {
    return request.post('/sys/concernTeacher/isConcernTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//关注讲师
export const concernTeacher = (params) => {
    return request.post('/sys/concernTeacher/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//取消关注讲师
export const removeConcern = (params) => {
    return request.post('/sys/concernTeacher/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//多少教务了关注讲师
export const countConcern = (params) => {
    return request.post('/sys/concernTeacher/countConcern', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//回关
export const updateByTeacherId = (params) => {
    return request.post('/sys/concernTeacher/updateByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export const currentTeachManagerId = (params) => {
    return request.post('/sys/teacher/currentTeachManagerId', params)
}


//学员管理、、、、、、、、、、、、、、、、
//学员一对一列表
export const alreadyDistribute = (params) => {
    return request.post('/sys/student/alreadyDistribute', params)
}


//新增学员列表
export const teacherManagerSave = (params) => {
    return request.post('/sys/student/teacherManagerSave', params)
}

//退回学生
export const returnStudentByStudentId = (params) => {
    return request.post('/sys/student/returnStudentByStudentId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//讲师个人信息
export const teacherInfo = (params) => {
    return request.post('/sys/teacher/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//讲师面试答题
export const getByTeacherId = (params) => {
    return request.post('/sys/teacherAnswer/getByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//配置讲师列表接口
export const configteacherPlan = (params) => {
    return request.post('/sys/configteacherPlan/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//根据学生id和学科id筛选出课程级别和课时单价接口
export const getConfigureCourseById = (params) => {
    return request.post('/sys/configureCourse/getConfigureCourseById', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//批量保存选择的老师接口
export const saveChooseTeacher = (params) => {
    return request.post('/sys/configteacherPlan/saveChooseTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//单个保存选择老师接口
export const saveOneChooseTeacher = (params) => {
    return request.post('/sys/configteacherPlan/saveOneChooseTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//单个删除选择老师接口
export const deleteOneChooseTeacher = (params) => {
    return request.post('/sys/configteacherPlan/delete', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//批量保存选择的老师接口
export const configteacherPlanList = (params) => {
    return request.post('/sys/configteacherPlan/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生上课需求列表接口
export const xmtStudentNeedsList = (params) => {
    return request.post('/sys/xmtStudentNeeds/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生添加需求接口
export const xmtStudentNeedsSave = (params) => {
    return request.post('/sys/xmtStudentNeeds/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//学生修改需求接口
export const xmtStudentNeedsUpdate = (params) => {
    return request.post('/sys/xmtStudentNeeds/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生上课需求详情接口
export const getDetailByStudentId = (params) => {
    return request.post('/sys/xmtStudentNeeds/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生上课需求删除接口
export const xmtStudentNeedsRemove = (params) => {
    return request.post('/sys/xmtStudentNeeds/remove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生上课需求详情接口
// export const getDetailByStudentId = (params) => {
//     return request.post('/sys/xmtStudentNeeds/getDetailByStudentId', params,{
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     })
// }

//上课地址列表
export const studentClassAddressList = (params) => {
    return request.post('/sys/studentClassAddress/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//上课地址列表新增
export const studentClassAddressSave = (params) => {
    return request.post('/sys/studentClassAddress/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//上课地址列表修改
export const studentClassAddressUpdate = (params) => {
    return request.post('/sys/studentClassAddress/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//上课地址列表删除
export const studentClassAddressRemove = (params) => {
    return request.post('/sys/studentClassAddress/remove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//上课地址列表详情
export const studentClassAddressInfo = (params) => {
    return request.post('/sys/studentClassAddress/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//判断是否有默认地址
export const checkDefault = (params) => {
    return request.post('/sys/studentClassAddress/checkDefault', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//该学生所购买过的产品
export const getProductByStudentId = (params) => {
    return request.post('/sys/product/getProductByStudentId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//排课前看一下这个老师和学生有么有被占用 单次排课
export const checkClassTime = (params) => {
    return request.post('/sys/xmtProductItem/checkClassTime', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//排课前看一下这个老师有么有被占用 批量排课
export const batchCheckTeacherClassTime = (params) => {
    return request.post('/sys/xmtProductItem/batchCheckTeacherClassTime', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//排课前看一下这个学生有么有被占用 批量排课
export const batchCheckStudentClassTime = (params) => {
    return request.post('/sys/xmtProductItem/batchCheckStudentClassTime', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//单次排课编辑前删除
export const xmtProductItemDelete= (params) => {
    return request.post('/sys/xmtProductItem/delete', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//新增排课接口
export const xmtProductItemSave = (params) => {
    return request.post('/sys/xmtProductItem/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//修改排课接口  一对一
export const xmtProductItemUpdate = (params) => {
    return request.post('/sys/xmtProductItem/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//修改排课接口  一对多
export const setAbnormalStatus = (params) => {
    return request.post('/sys/xmtProductItem/setAbnormalStatus', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//批量排课接口
export const xmtProductItembatchSave = (params) => {
    return request.post('/sys/xmtProductItem/batchSave', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//批量取消排课接口
export const xmtProductItemRemove = (params) => {
    return request.post('/sys/xmtProductItem/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//查询排课详情接口
export const xmtProductItemInfo = (params) => {
    return request.post('/sys/xmtProductItem/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//学生一周课程接口
export const findCourseItem = (params) => {
    return request.post('/sys/xmtProductItem/findCourseItem', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//查询剩余金额接口
export const getResiduePrice = (params) => {
    return request.post('/sys/xmtProductItem/getResiduePrice', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/*  批量保存选择的老师接口*/
export const saveChooseTeacherMore = (params) => {
    return request.post('/sys/configteacherPlan/saveChooseTeacher', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//排课前看一下这个老师有么有被占用
export const checkTeacherClassTime = (params) => {
    return request.post('/sys/xmtProductItem/checkTeacherClassTime', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//排课前看一下这个学生有么有被占用
export const checkStudentClassTime = (params) => {
    return request.post('/sys/xmtProductItem/checkStudentClassTime', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}



//课时查询列表  一对一
export const findProductItemPage = (params) => {
    return request.post('/sys/xmtProductItem/findProductItemPage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//课时查询批量确认课时
export const confirmCourse = (params) => {
    return request.post('/sys/xmtProductItem/confirmCourse', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课程评价详情接口描述
export const getXmtStudentAccessClassByItemId = (params) => {
    return request.post('/sys/studentAccessClass/getXmtStudentAccessClassByItemId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课程评价详情接口描述new
export const getInfoByItemId = (params) => {
    return request.post('/sys/teacherEvaluated/getInfoByItemId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课堂反馈上课内容下转接口描述
export const listSyncTree = (params) => {
    return request.post('/sys/a/exam/knowledge/listSyncTree', qs.stringify(params))
}

//课时管理的课后管理的课堂反馈详情接口描述
export const productItemLearnReportInfo = (params) => {
    return request.post('/sys/productItemLearnReport/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//保存接口
export const saveOrUpdate = (params) => {
    return request.post('/sys/productItemLearnReport/saveOrUpdate', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//部门课表一对多接口
export const getSyllabusPageDeptMore = (params) => {
    return request.post('/sys/xmtProductItem/getSyllabusPageDeptMore', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//部门课表一对一接口
export const getSyllabusPageDeptOne = (params) => {
    return request.post('/sys/xmtProductItem/getSyllabusPageDeptOne', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课耗一对多
export const findMoreProductItemPage = (params) => {
    return request.post('/sys/xmtProductItem/findMoreProductItemPage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课耗取消课
export const findDelProductItemPage = (params) => {
    return request.post('/sys/xmtProductItem/findDelProductItemPage', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//批量删除修改后的接口
export const batchCancel = (params) => {
    return request.post('/sys/xmtProductItem/batchCancel', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教务学生增加跟进记录接口描述
export const teacherFollowupRecordSave = (params) => {
    return request.post('/sys/teacherFollowupRecord/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//教务学生跟进记录列表接口描述
export const teacherFollowupRecordList = (params) => {
    return request.post('/sys/teacherFollowupRecord/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教务修改跟进记录接口描述
export const teacherFollowupRecordUpdate = (params) => {
    return request.post('/sys/teacherFollowupRecord/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课单管理列表接口
export const orderPublishList = (params) => {
    return request.post('/sys/orderPublish/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课单管理新增接口
export const orderPublishSave = (params) => {
    return request.post('/sys/orderPublish/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课单管理详情接口
export const orderPublishInfo = (params) => {
    return request.post('/sys/orderPublish/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课单管理修改接口
export const orderPublishUpdate = (params) => {
    return request.post('/sys/orderPublish/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课单管理删除接口
export const orderPublishRemove = (params) => {
    return request.post('/sys/orderPublish/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//添加评价讲师接口
export const teacherEvaluatedSave = (params) => {
    return request.post('/sys/teacherEvaluated/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//查看教务评价老师统计接口描述
export const teacherEvaluatedList = (params) => {
    return request.post('/sys/teacherEvaluated/listCount', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//设置匿名接口描述
export const teacherEvaluatedUpdate = (params) => {
    return request.post('/sys/teacherEvaluated/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//点好评中评差评调的评价详情
export const teacherEvaluatedContent = (params) => {
    return request.post('/sys/teacherEvaluated/list', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//删除评价接口描述
export const teacherEvaluatedRemove = (params) => {
    return request.post('/sys/teacherEvaluated/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//修改讲师设备状态
export const teacherUpdateEquipment = (params) => {
    return request.post('/sys/teacher/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//老师上课详情
export const getTeacherClassDetailsCount= (params) => {
    return request.post('/sys/teacher/getTeacherClassDetailsCount', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课表 一对多获取班级人
export const getStudentInfoByRoomId= (params) => {
    return request.post('/sys/classroomStudent/getStudentInfoByRoomId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//退费审核判断是否显示
export const checkRefundOrders= (params) => {
    return request.post('/sys/studentRefund/checkRefundOrders', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//讲师排课量列表接口
export const teacherSchedulingList= (params) => {
    return request.post('sys/teacher/teacherSchedulingList', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//上课记录接口
export const teacherSchedulingDetail= (params) => {
    return request.post('/sys/teacher/teacherSchedulingDetail', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}



//推荐接口
export const suggestTeacher= (params) => {
    return request.post('/sys/teacher/suggestTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//取消推荐接口
export const cancelSuggestTeacher= (params) => {
    return request.post('/sys/teacher/cancelSuggestTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//获取订单的总金额
export const getOtoPriceByStudentId= (params) => {
    return request.post('/sys/order/getOtoPriceByStudentId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//点添加配置讲师调的
export const checkHistoryChooseTeacher= (params) => {
    return request.post('/sys/configteacherPlan/checkHistoryChooseTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//设置老师为常用
export const setOftenTeacher= (params) => {
    return request.post('/sys/concernTeacher/setOftenTeacher', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//查看老师为常用
export const selectOftenUsed= (params) => {
    return request.post('/sys/concernTeacher/selectOftenUsed', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//分组新增
export const concernTeacherGroupSave= (params) => {
    return request.post('/sys/concernTeacherGroup/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//分组列表
export const concernTeacherGroupList= (params) => {
    return request.post('/sys/concernTeacherGroup/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//分组列表删除
export const removeByGroupId= (params) => {
    return request.post('/sys/concernTeacherGroup/removeByGroupId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//分组列表修改
export const concernTeacherGroupUpdate= (params) => {
    return request.post('/sys/concernTeacherGroup/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//老师上课时间
export const getFreetimeList= (params) => {
    return request.post('/sys/xmtProductItem/listTeacherCourse', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//跟进记录删除
export const teacherFollowupRecordRemove= (params) => {
    return request.post('/sys/teacherFollowupRecord/remove', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//班里列表  前台推送
export const classroomUpdatepush= (params) => {
    return request.post('/sys/classroom/update', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课时管理  确认课时  9.23
export const confirmClassHour= (params) => {
    return request.post('/sys/xmtProductItem/confirmClassHour', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


//单次排课筛选讲师机构接口
export const checkTeacherOrganClassTime= (params) => {
    return request.post('/sys/xmtProductItem/checkTeacherOrganClassTime', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//批量排课筛选讲师机构接口
export const batchCheckTeacherOrganClassTime= (params) => {
    return request.post('/sys/xmtProductItem/batchCheckTeacherOrganClassTime', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教师排课量创建人
export const addTeacherCreatePeople= (params) => {
    return request.post('/sys/user/addTeacherCreatePeople', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//设置分组
export const setTeacherGroup= (params) => {
    return request.post('/sys/concernTeacher/setTeacherGroup', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//定向发单老师列表
export const listTeacherTeble= (params) => {
    return request.post('/sys/concernTeacher/listTeacherTeble', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//定向发单老师发送
export const updateOrientOrderPublish= (params) => {
    return request.post('/sys/orderPublish/updateOrientOrderPublish', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
/* 章节知识点相关 */
/* parentId: 320005 */
export const knowledgeTree = (params) => {
    return request.post('/sys/a/exam/knowledge/listSyncTree', qs.stringify(params))
}
/* 文件上传 */
export const fileUpload = (params) => {
    return request.post('/interface/file/upload', params)
}
export const fileUploads = (params) => {
    return request.post('/interface/file/upload', params)
}
export const fileUploadUrl = '/interface/file/upload'

// 学生空闲时间
export const listStudentCourse = (params) => {
    return request.post('/sys/xmtProductItem/listStudentCourse', params)
}
export const addFreetime = (params) => {
    return request.post('/sys/xmtProductItem/addFreetime', params)
}
export const batchRemoveFreetime = (params) => {
    return request.post('/sys/xmtProductItem/batchRemoveFreetime', params)
}

export const removeByStuId = (params) => {
    return request.post('/sys/academicrecord/removeByStuId', params)
}


//课时管理-发送作业列表
export const productItemWorkList= (params) => {
    return request.post('/sys/productItemWork/list', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课时管理-发送作业新增
export const productItemWorkSave= (params) => {
    return request.post('/sys/productItemWork/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课时管理-发送作业删除
export const productItemWorkRemove= (params) => {
    return request.post('/sys/productItemWork/remove', params)
}

//课时管理-发送作业预览
export const productItemWorkSaveInfo= (params) => {
    return request.post('/sys/productItemWork/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//恢复课程
export const resumeClass= (params) => {
    return request.post('/sys/xmtProductItem/resumeClass', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//学员详情里的一对一订单列表
export const oneToOneList= (params) => {
    return request.post('/sys/order/oneToOneList', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//单次排课 一对一充值
export const saveOneToOne= (params) => {
    return request.post('/sys/xmtProductItem/saveOneToOne', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//批次排课 一对一充值
export const batchSaveOneToOne= (params) => {
    return request.post('/sys/xmtProductItem/batchSaveOneToOne', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//讲师修改
export const parentCodes= (params) => {
    return request.post('/sys/macro/list/parentCode', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//设置学生状态
export const setStudentState= (params) => {
    return request.post('/sys/student/setStudentState', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//课时管理-首评
export const teacherFirstEvaluatedSave= (params) => {
    return request.post('/sys/teacherFirstEvaluated/save', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//课时管理-首评详情
export const teacherFirstEvaluated= (params) => {
    return request.post('/sys/teacherFirstEvaluated/getInfoByItemId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//教务评价讲师
export const saveByTeacherManager= (params) => {
    return request.post('/sys/teacherEvaluated/saveByTeacherManager', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//查找历史评价列表，和我的评价列表
export const getListByTeacherManagerlist= (params) => {
    return request.post('/sys/teacherEvaluated/getListByTeacherManagerlist', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//评价详情
export const teacherEvaluatedInfo= (params) => {
    return request.post('/sys/teacherEvaluated/info', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//评价详情中的教务评价标签统计
export const getListEvaluatedCount= (params) => {
    return request.post('/sys/teacherEvaluated/getListEvaluatedCount', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//讲师上课详情统计
export const getDetailCountByTeacherId= (params) => {
    return request.post('/sys/xmtProductItem/getDetailCountByTeacherId', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//补课时新增
export const makeUpTimeForClass= (params) => {
    return request.post('/sys/xmtProductItem/makeUpTimeForClass', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//补课时删除
export const deleteMakeUpTimeForClass= (params) => {
    return request.post('/sys/xmtProductItem/deleteMakeUpTimeForClass', params,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

