import request from '@/utils/request'
import qs from 'qs'

export const studentList = (params) => {
    return request.post('/sys/student/list', params)
}
export const getNewCustomerPool = (params) => {
    return request.post('/sys/student/getNewCustomerPool', params)
}
//已分配的客户
export const getAssignedCustomers = (params) => {
    return request.post('/sys/student/getAssignedCustomers', params)
}
export const studentUpdate = (params) => {
    return request.post('/sys/student/update', params)
}
export const deleteStudentToken = (params) => {
    return request.post('/sys/student/deleteTokenByStudentId', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const studentDelete = (params) => {
    return request.post('/sys/student/remove', params)
}
export const reapportionList = (params) => {
    return request.post('/sys/student/reapportionList', params)
}
export const listNoPage = (params) => {
    return request.post('/sys/user/listNoPage', params)
}
export const studentImport = (params) => {
    return request.post("/sys/student/importFile", params)
}
export const getSaleId = (params) => {
    return request.get('/sys/student/getUserAreaId', params)
}
export const studentInfo = (params) => {
    return request.post('/sys/student/info', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const studentSave = (params) => {
    return request.post('/sys/student/save', params)
}
export const infoByParentCodeAndValue = (params) => {
    return request.post('/sys/macro/infoByParentCodeAndValue', qs.stringify(params))
}
export const saleBack = (params) => {
    return request.post('/sys/student/sale/back', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const academicrecordSave = (params) => {
    return request.post('/sys/academicrecord/save', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const getObjectById = (params) => {
    return request.post('/sys/academicrecord/getObjectById', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}   

//删除成绩
export const academicrecordRemove = (params) => {
    return request.post('/sys/academicrecord/remove', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
} 

//编辑成绩
export const academicrecordUpdate = (params) => {
    return request.post('/sys/academicrecord/update', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
} 

//学生基础信息有效失效切换接口
export const ifEffectiveBaseStudent = (params) => {
    return request.post('/sys/student/ifEffectiveBaseStudent', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//教务专员--退费前的详情
export const refundOneToOneInfos = (params) => {
    return request.post('/sys/studentRefund/refundOneToOneInfos', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//教务专员--提交退费审核
export const saveOneToOne = (params) => {
    return request.post('/sys/studentRefund/saveOneToOne', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
//教务专员--预览审核查看接口
export const getOneToOneInfo = (params) => {
    return request.post('/sys/studentRefund/getOneToOneInfo', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//退费通过驳回
export const examineOneToOne = (params) => {
    return request.post('/sys/studentRefund/examineOneToOne', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//退费修改
export const updateOneToOne = (params) => {
    return request.post('/sys/studentRefund/updateOneToOne', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

//退费修改
export const orderCreatName = (params) => {
    return request.post('/sys/user/orderCreatName', params, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}