
<script>
const publicBaseUrl = process.env.VUE_APP_PUBLIC_BASE_URL
const webPc = `${publicBaseUrl}/webPc/#/pcLivePage?roomID=`;
const webMobile = `${publicBaseUrl}/webMobile/#/mobileLivePage?roomID=`;
const mobileType = `${publicBaseUrl}/mobileAdmin/index.html#/classRoomFeedback?`//课堂反馈
const learnType=`${publicBaseUrl}/learnReportWeb/index.html#/report?`//学习报告
export default {
    webPc,
    webMobile,
    mobileType,
    learnType
};
</script>