import request from '@/utils/request'
import qs from 'qs'

export const login = (params) => {
    return request.post('/sys/login', qs.stringify(params))
}
export const getCode = (params) => {
    return request.get('/sys/getCode', params)
}
export const logout = (params) => {
    return request.post('/sys/logout', params)
}
export const modifyPassword = (params) => {
    return request.post('/sys/user/updatePswd', qs.stringify(params))
}