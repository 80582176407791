import request from '@/utils/request'
export const fileUpload = '/interface/file/upload'

export const phaseCode = 'sys.teach.phase'//学段
export const sectionCode = 'sys.teach.section'//高一高二
export const subjectCode = 'sys.teach.subject'//语数外
export const teachSpeed = 'sys.teach.speed'
export const teachStyle = 'sys.teach.style'
export const teachYear = 'sys.teach.year'
export const studentLearnNeed = 'sys.student.learn.need'
export const studentLevel = 'sys.student.level'
export const teachMandarin = 'sys.teach.mandarin'
export const teachTitle = 'sys.teach.title'
export const teachEdition = 'sys.teach.edition'
export const resultCode ='sys.student.achievement'//学生成绩
export const mandarinCode='sys.teach.mandarin'//普通话
export const teachAge = 'sys.teach.age'//教龄
export const achievement = 'sys.teacher.achievement'//擅教学生
export const readingStage = 'sys.teacher.readingStage'//在读阶段
export const aisciplineProfessorSection = 'sys.teacher.aisciplineProfessorSection'//学科类--教授学段
export const disciplineProfessorGrade = 'sys.tescher.disciplineProfessorGrade'//学科类--教授年级
export const subjectTeachingSubject = 'sys.teacher.disciplineTeachingSubject'//学科类--教学科目
export const qualityClassProfessorSection = 'sys.teacher.qualityClassProfessorSection'//素质类--教学学段
export const qualityClassTeachingGrade = 'sys.teacher.qualityClassTeachingGrade'//素质类--教学年级
export const qualityTeachingSubjects = 'sys.teacher.qualityTeachingSubjects'//素质类--教授科目
export const qualityStudentsType ='sys.student.qualityStudentsType'//素质类--学生类型
//跟进标签
export const followTags = 'sys.follow.tags'
//星座列表
export const constellation = 'sys.student.constellation'

export const imageGood ='sys.teacher.evaluation.image.good'//评价老师-形象-正面
export const imageBad ='sys.teacher.evaluation.image.bad'//评价老师-形象-反面
export const teachingGood ='sys.teacher.evaluation.teaching.good'//评价老师-教学-正面
export const teachingBad ='sys.teacher.evaluation.teaching.bad'//评价老师-教学-反面
export const styleGood ='sys.teacher.evaluation.teaching.style.good'//评价老师-授课风格-正面
export const styleBad ='sys.teacher.evaluation.teaching.style.bad'//评价老师-授课风格-反面
export const otherGood ='sys.teacher.evaluation.other.good'//评价老师-其他-正面
export const otherBad ='sys.teacher.evaluation.other.bad'//评价老师-其他-反面
export const getSelectList = (params) => {
    return request.post('sys/macro/list/parentCode', params)
}

export const inferfaceFileUpload = (params) => {
    return request.post('/interface/file/upload', params)
}


export const reg_Num = (value) => {
    let reg
    switch(value){
        case 1: 
            //验证零和非零开头的数字
            reg = new RegExp('^(0|[1-9][0-9]*)$')
            break
        case 2: 
            //验证非零的正整数
            reg = new RegExp('^[1-9][0-9]*$')
            break
        case 3: 
            //验证有两位小数的正实数(不包含0)
            reg = new RegExp('^(0.[0-9]{1,2}|[1-9][0-9]*(.[0-9]{1,2})?)$')
            break
        case 4: 
            //验证有两位小数的正实数(包含0)
            reg = new RegExp('^(0|[1-9][0-9]*)(.[0-9]{1,2})?$')
            break
        
    }
    return reg
} 